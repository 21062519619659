<script setup lang="ts">
interface Props {
    prependIcon?: string; // Иконка, которая будет отображаться перед текстом кнопки.
    variant?: "elevated" | "flat" | "text" | "tonal" | "outlined" | "plain"; // Вариант кнопки, который определяет ее стиль.
    styleClass?: string | string[]; // Дополнительные классы стилей для кнопки.
    isMenuButton?: boolean; // Указывает, является ли кнопка кнопкой меню.
    link?: string; // Ссылка для кнопки (если нужно поведение ссылки)
    disabled?: boolean; // Заблокировать кнопку
};

withDefaults(defineProps<Props>(), {
    prependIcon: '', 
    variant: 'elevated', 
    styleClass: '', 
    isMenuButton: false,
    link: '',
    disabled: false,
});

const emit = defineEmits(['click']);
</script>

<template>
    <v-btn
        :prependIcon="prependIcon"
        :variant="variant"
        :href="link || undefined"
        class="button"
        :disabled="disabled"
        :class="[{'menu-button': isMenuButton}, styleClass]"
        @click="emit('click')"
    >
        <slot/>
    </v-btn>
</template>

<style scoped lang="scss">
:deep(.v-btn__prepend) {
    margin-inline: 0;

    /** Чтобы работала custom иконка */
    .mdi:before,
    .mdi:after {
        font: inherit;
        font-size: 20px;
    }
}

.v-btn--elevated:hover, .v-btn--elevated:focus{
    box-shadow: none;
}

.v-btn--variant-outlined {
    background-color: transparent;
    border: 1px solid var(--Color-Zink-200, $border-color);
}
.v-btn--variant-elevated{
    background: var(--Color-Zink-950, $main-active-color);
    color: var(--White-White, $main--contrast-text-color);
    box-shadow: none;
}

.button {
    --v-btn-height: 52px;

    display: flex;
    padding: 24px 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: var(--Border-radius-rounded-lg, 12px);

    font-size: 16px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.16px;

    text-transform: none;

    @include responsive( 'mobile' ) {
        width: 100%;
    }

    &.menu-button {
        --v-btn-height: 36px;
        min-width: var(--Padding-pa-9, 36px);
        min-height: var(--Padding-pa-9, 36px);
        padding: var(--Padding-pa-2, 8px);
        gap: 0;
    }

    &.v-btn--disabled {
        padding: var(--Padding-pa-4, 16px);
        background: var(--Color-Zink-200, $border-color);
    }
}
</style>
